/**-----------------------------------------------------------------------------------------
* Copyright © 2020 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import { __decorate, __param, __metadata } from 'tslib';
import { Directive, Optional, TemplateRef, Input, Component, ContentChild, ElementRef, NgModule } from '@angular/core';
import { drawDOM, exportPDF } from '@progress/kendo-drawing';
import { saveAs } from '@progress/kendo-file-saver';
import { validatePackage } from '@progress/kendo-licensing';

/**
 * @hidden
 */
const packageMetadata = {
    name: '@progress/kendo-angular-pdf-export',
    productName: 'Kendo UI for Angular',
    productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
    publishDate: 1620215018,
    version: '',
    licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/?utm_medium=product&utm_source=kendoangular&utm_campaign=kendo-ui-angular-purchase-license-keys-warning'
};

let PDFExportTemplateDirective = class PDFExportTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
PDFExportTemplateDirective = __decorate([
    Directive({
        selector: '[kendoPDFTemplate]'
    }),
    __param(0, Optional()),
    __metadata("design:paramtypes", [TemplateRef])
], PDFExportTemplateDirective);

const FIELDS = ['bottom', 'left', 'right', 'top'];
/**
 * Represents the Kendo UI PDFMargin component for Angular.
 */
let PDFExportMarginComponent = class PDFExportMarginComponent {
    /**
     * @hidden
     */
    get options() {
        const options = {};
        for (let idx = 0; idx < FIELDS.length; idx++) {
            const field = FIELDS[idx];
            const value = this[field];
            if (typeof value !== 'undefined') {
                options[field] = value;
            }
        }
        return options;
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], PDFExportMarginComponent.prototype, "left", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], PDFExportMarginComponent.prototype, "top", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], PDFExportMarginComponent.prototype, "right", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], PDFExportMarginComponent.prototype, "bottom", void 0);
PDFExportMarginComponent = __decorate([
    Component({
        selector: 'kendo-pdf-export-margin',
        template: ``
    })
], PDFExportMarginComponent);

/**
 * @hidden
 */
const compileTemplate = (templateRef) => {
    const context = {};
    let embeddedView = templateRef.createEmbeddedView(context);
    const result = (data) => {
        Object.assign(context, data);
        embeddedView.detectChanges();
        const templateWrap = document.createElement('span');
        embeddedView.rootNodes.forEach((rootNode) => {
            templateWrap.appendChild(rootNode.cloneNode(true));
        });
        return templateWrap;
    };
    result.destroy = () => {
        embeddedView.destroy();
        embeddedView = null;
    };
    return result;
};

/**
 * Represents the [Kendo UI PDF Export component for Angular]({% slug overview_pdfexport %}).
 *
 * @example
 * ```ts-preview
 * _@Component({
 *   selector: 'my-app',
 *   template: `
 *     <div class="example-config">
 *       <button class="k-button" (click)="pdf.saveAs('document.pdf')">
 *         Save As PDF...
 *       </button>
 *     </div>
 *
 *     <kendo-pdf-export #pdf paperSize="A4" margin="2cm">
 *       Content goes here
 *     </kendo-pdf-export>
 *   `
 * })
 * export class AppComponent {
 * }
 * ```
 */
let PDFExportComponent = class PDFExportComponent {
    constructor(element) {
        this.element = element;
        /**
         * The creator of the PDF document.
         * @default "Kendo UI PDF Generator"
         */
        this.creator = 'Kendo UI PDF Generator';
        /**
         * Specifies the name of the exported PDF file.
         * @default "Export.pdf"
         */
        this.fileName = 'export.pdf';
        validatePackage(packageMetadata);
    }
    get drawMargin() {
        const marginComponent = this.marginComponent;
        let margin = this.margin;
        if (marginComponent) {
            margin = Object.assign(margin || {}, marginComponent.options);
        }
        return margin;
    }
    /**
     * Saves the content as a PDF file with the specified name.
     * @param fileName - The name of the exported file.
     */
    saveAs(fileName = this.fileName) {
        this.save(this.element.nativeElement, fileName);
    }
    /**
     * Exports the content as a `Group` for further processing.
     *
     * @return - The root group of the exported scene.
     */
    export() {
        return this.exportElement(this.element.nativeElement);
    }
    save(element, fileName) {
        this.exportElement(element)
            .then(group => this.exportGroup(group, this.pdfOptions()))
            .then(dataUri => this.saveDataUri(dataUri, fileName, this.saveOptions()));
    }
    exportElement(element) {
        const promise = this.drawElement(element, this.drawOptions());
        const cleanup = this.cleanup.bind(this);
        promise.then(cleanup, cleanup);
        return promise;
    }
    cleanup() {
        if (this.pageTemplate) {
            this.pageTemplate.destroy();
            delete this.pageTemplate;
        }
    }
    drawOptions() {
        if (this.pageTemplateDirective) {
            this.pageTemplate = compileTemplate(this.pageTemplateDirective.templateRef);
        }
        return {
            avoidLinks: this.avoidLinks,
            forcePageBreak: this.forcePageBreak,
            keepTogether: this.keepTogether,
            margin: this.drawMargin,
            paperSize: this.paperSize,
            landscape: this.landscape,
            repeatHeaders: this.repeatHeaders,
            scale: this.scale,
            template: this.pageTemplate
        };
    }
    pdfOptions() {
        return {
            autoPrint: this.autoPrint,
            author: this.author,
            creator: this.creator,
            date: this.date,
            imgDPI: this.imageResolution,
            keywords: this.keywords,
            landscape: this.landscape,
            margin: this.drawMargin,
            multiPage: true,
            paperSize: this.paperSize,
            producer: this.producer,
            subject: this.subject,
            title: this.title
        };
    }
    saveOptions() {
        return {
            forceProxy: this.forceProxy,
            proxyData: this.proxyData,
            proxyTarget: this.proxyTarget,
            proxyURL: this.proxyURL
        };
    }
    drawElement(element, options) {
        return drawDOM(element, options);
    }
    exportGroup(group, options) {
        return exportPDF(group, options);
    }
    saveDataUri(dataUri, fileName, options) {
        saveAs(dataUri, fileName, options);
    }
};
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], PDFExportComponent.prototype, "autoPrint", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], PDFExportComponent.prototype, "author", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], PDFExportComponent.prototype, "avoidLinks", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], PDFExportComponent.prototype, "forcePageBreak", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], PDFExportComponent.prototype, "keepTogether", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], PDFExportComponent.prototype, "creator", void 0);
__decorate([
    Input(),
    __metadata("design:type", Date)
], PDFExportComponent.prototype, "date", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], PDFExportComponent.prototype, "imageResolution", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], PDFExportComponent.prototype, "fileName", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], PDFExportComponent.prototype, "forceProxy", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], PDFExportComponent.prototype, "keywords", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], PDFExportComponent.prototype, "landscape", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], PDFExportComponent.prototype, "margin", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], PDFExportComponent.prototype, "paperSize", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], PDFExportComponent.prototype, "repeatHeaders", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], PDFExportComponent.prototype, "scale", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], PDFExportComponent.prototype, "proxyData", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], PDFExportComponent.prototype, "proxyURL", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], PDFExportComponent.prototype, "proxyTarget", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], PDFExportComponent.prototype, "producer", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], PDFExportComponent.prototype, "subject", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], PDFExportComponent.prototype, "title", void 0);
__decorate([
    ContentChild(PDFExportTemplateDirective, { static: false }),
    __metadata("design:type", PDFExportTemplateDirective)
], PDFExportComponent.prototype, "pageTemplateDirective", void 0);
__decorate([
    ContentChild(PDFExportMarginComponent, { static: false }),
    __metadata("design:type", PDFExportMarginComponent)
], PDFExportComponent.prototype, "marginComponent", void 0);
PDFExportComponent = __decorate([
    Component({
        selector: 'kendo-pdf-export',
        template: `<div><ng-content></ng-content></div>`
    }),
    __metadata("design:paramtypes", [ElementRef])
], PDFExportComponent);

const COMPONENT_DIRECTIVES = [
    PDFExportComponent,
    PDFExportMarginComponent,
    PDFExportTemplateDirective
];
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }}) definition for the PDF Export directive.
 */
let PDFExportModule = class PDFExportModule {
};
PDFExportModule = __decorate([
    NgModule({
        declarations: [COMPONENT_DIRECTIVES],
        exports: [COMPONENT_DIRECTIVES]
    })
], PDFExportModule);

/**
 * Generated bundle index. Do not edit.
 */

export { PDFExportComponent, PDFExportModule, PDFExportMarginComponent, PDFExportTemplateDirective, compileTemplate };
